@import '../../styles/variables/index.scss';
.login-page {
  text-align: center;

  .login-page__logo {
    font-size: 60px;
    padding-top: 20vh;
    padding-bottom: 60px;

    @include breakpoint($lt-sm) {
      font-size: 36px;
      padding-top: 30vh;
      padding-bottom: 30px;
    }

    @include breakpoint($lt-xs) {
      font-size: 30px;
    }
  }

  .login-page__form {
    width: 100%;
    max-width: 500px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
  }

  .login-page__form-icon {
    padding-right: 10px;
  }

  .login-page__send-letter-message {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
}
