@import '../../../styles/variables/index.scss';
.default-layout {
  min-height: 100vh !important;

  .default-layout__header-logo {
    color: $white;
    font-weight: bold;
    font-size: 1.3rem;
    padding-left: 25px;
  }

  .default-layout__header-trigger {
    color: $white;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $blue;
    }
  }

  .default-layout__sider {
    @include breakpoint($lt-md) {
      height: calc(100% - 64px); //64px - header height
      position: absolute;
    }
  }

  .default-layout__sider-menu {
    padding-top: 12px;
  }

  .default-layout__content {
    padding: 25px;
    background-color: $default-bg;
  }
}

.ant-layout-header {
  padding: 0 0!important;
}
