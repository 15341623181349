.ck.ck-editor__top.ck-reset_all {
    z-index: var(--ck-z-modal);
    position: sticky;
    top: -24px;
    border-bottom: 1px solid var(--ck-color-toolbar-border);
  }
  
  // .ck.ck-sticky-panel__placeholder {
  //   display : none !important;
  // }
  
  // .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  //   position: unset;
  // }